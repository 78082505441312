@media screen and (max-width: 576px) {
    .contactUs {
        margin-top: 0px !important;
    }
}
@media screen and (max-width: 768px) {
    .contactUsHeading{
        margin-left: 70px !important;
    }
}
