@media screen and (max-width: 1255px) {
    .mainContent {
        width: 90% !important;
        margin: 0 5% !important;
    }
}

@media screen and (max-width: 576px) {
    .mainContentImges {
        display: none !important;
    }

    .mainSection {
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

    .smAbout {
        margin: 20px 50px 0px 50px !important;
    }

    .smPara {
        margin: 0px 40px !important;
    }

    .smCards {
        margin: 0px !important;
    }

    .value {
        margin-bottom: 25px !important;
    }
}