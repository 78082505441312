@media screen and (max-width: 576px) {
    .mainHead {
        display: none;
    }
}

.mainNav {
    margin-right: 90px !important;
}

@media screen and (max-width: 768px) {

    .mainNav {
        margin-right: 0px !important;
    }
}

.mainBtn {
    text-transform: none !important;
    font-family: "Montserrat" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    /* color: black !important; */
}