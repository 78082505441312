/* .menu {
  --menu-height: 40px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
} */
.menu ul {
  list-style: none;
  padding: 16px;
  margin: 0;
}

.menu ul li,
.menu ul li a {
  opacity: 1;
  /* color: red; */
  cursor: pointer;
  /* transition: 200ms; */
  text-decoration: none;
  white-space: nowrap;
  font-weight: 700;
  color: #656565 !important;
}

.menu ul li,
.menu ul li a:hover {
  opacity: 1;
  cursor: pointer;
  /* transition: 200ms; */
  text-decoration: none;
  white-space: nowrap;
  font-weight: 700;
  color: #000000 !important;
}

.menu ul li a,
.menu ul li a a {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.link:hover {
  background-color: #f5f6f6 !important;
}

.menu ul li {
  padding-right: 36px;
}

.menu ul li::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #656565;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.menu ul .link::before {
  padding-right: 0;
  display: none;
}

.menu>ul {
  display: flex;
  height: var(--menu-height);
  align-items: center;
  /* background-color: #000000; */
}

.menu>ul li {
  position: relative;
  margin: 0 8px;
  color: black;
}

.menu>ul li ul {
  visibility: hidden;
  opacity: 0;
  padding: 0;
  min-width: 160px;
  background-color: #ffffff;
  position: absolute;
  top: calc(var(--menu-height) + 5px);
  left: 50%;
  transform: translateX(-50%);
  /* transition: 200ms;
  transition-delay: 200ms; */
}

.menu>ul li ul li {
  margin: 0;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  color: black;
  padding-right: 40px;
}

.menu>ul li ul li::before {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000000;
}

.menu>ul li ul li ul {
  top: -2%;
  left: 100%;
  transform: translate(0);
}

/* .menu > ul li ul li:hover {
  background-color: #000000;
} */
.menu>ul li:hover>ul {
  opacity: 1;
  visibility: visible;
  /* transition-delay: 0ms; */
}

@media screen and (max-width: 768px) {
  .menu {
    display: none;
  }
}